











































































import Vue from "vue";
import Component from "vue-class-component";
import { Watch } from "vue-property-decorator";
import {
  BrowserMultiFormatReader,
  BarcodeFormat,
  DecodeHintType,
} from "@zxing/library";
import axios from "axios";

@Component
export default class App extends Vue {
  scanning = false;
  reader!: BrowserMultiFormatReader;
  result = {
    product: null,
  };

  startScan(): void {
    this.scanning = true;
    const hints = new Map();
    const formats = [
      BarcodeFormat.UPC_A,
      BarcodeFormat.UPC_E,
      BarcodeFormat.EAN_8,
      BarcodeFormat.EAN_13,
    ];

    hints.set(DecodeHintType.POSSIBLE_FORMATS, formats);

    this.reader = new BrowserMultiFormatReader(hints);
    this.reader.listVideoInputDevices().then((videoInputDevices) => {
      videoInputDevices.forEach((element) => {
        console.log(`${element.label}, ${element.deviceId}`);
      });
      const firstDeviceId = videoInputDevices[0].deviceId;
      this.reader
        .decodeOnceFromVideoDevice(undefined, "video")
        .then(this.handleDecode)
        .catch((err) => {
          console.error(err);
        });
    });
  }

  loadData(code: string): void {
    axios
      .get(`https://world.openfoodfacts.org/api/v0/product/${code}.json`)
      .then((res) => {
        this.result = res.data;
        console.log(res.data);
      });
  }

  handleDecode(data: any): void {
    console.log(data.text);
    this.loadData(data.text);
    this.endScan();
  }

  endScan() {
    console.log("scan ended");
    this.reader.reset();
    this.scanning = false;
  }

  @Watch("scanning")
  scanChanged(): void {
    if (!this.scanning) this.endScan();
  }
}
