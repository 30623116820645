import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import AddFood from "../views/AddFood.vue";
import ViewMe from "../views/ViewMe.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "AddFood",
    component: AddFood,
  },
  {
    path: "/me/",
    name: "ViewMe",
    component: ViewMe,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
