import { render, staticRenderFns } from "./ViewMe.vue?vue&type=template&id=b941bc72&"
import script from "./ViewMe.vue?vue&type=script&lang=ts&"
export * from "./ViewMe.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.9.8_679359cdb69c218f2f8f476b2ba08796/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/.pnpm/vuetify-loader@1.7.3_288b7a57920cc629c88fcfec62cece2c/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VContainer})
