






















































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component
export default class AddFood extends Vue {
  @Prop()
  loading!: boolean;
  @Prop()
  product!: any;

  products = [{ name: "Apfel" }, { name: "Toast" }, { name: "Apfel" }];

  search = "";

  searchBarcode(): void {
    (this.$refs.searchField as HTMLInputElement).blur();
    this.$emit("searchBarcode", this.search);
  }

  get searchIsBarcode(): boolean {
    console.log(this.search);
    return /^\d+$/.test(this.search);
  }

  get wwPoints(): number {
    let product = this.product;
    return Math.round(
      product.nutriments["energy-kcal_100g"] / 50 +
        product.nutriments["fat_100g"] / 12 -
        Math.min(product.nutriments["fiber_100g"] || 0, 4) / 5
    );
  }

  test = "";
}
